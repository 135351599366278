import BannerImage from "../landing/BanerImage";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Icons from "../../models/Icons";
import ScrollToTopOnMount from "../../template/ScrollToTopOnMount";
import AnimateSection from "../animate/AnimateSection";

const CompanyView = () => {

    const image = process.env.PUBLIC_URL + "/images/banner_08.png";
    const text = '40 Years of Tradition & Experience in Road Signalization';
    const headerStyle = {position: 'absolute', top: 40, left: 65, width: '90%', textAlign: 'left'};
    const iconStyle = {width: 80, height: 80};
    const textStyle = {marginBottom: 0};

    return <>
        <ScrollToTopOnMount/>
        <BannerImage image={image} text={text}/>
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2 g-3 mt-1 flex-shrink-0 row-cols-xl-3 w-100">&nbsp;</div>
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2 g-3 mb-4 flex-shrink-0 row-cols-xl-2" style={{maxWidth: 1200, margin: '0 auto', overflow: 'hidden'}}>
            <AnimateSection>
                <div className="col p-2 m-0 mb-3">
                    <div className="card p-3">
                        <h5 className="text-left px-5" style={headerStyle}>
                            <span>History</span>
                            <div style={{display: 'inline-block', float: 'right'}}>
                                <FontAwesomeIcon icon={['fas', 'ellipsis-h']} style={{color: 'red'}}/>&nbsp;
                                <FontAwesomeIcon icon={['fas', 'ellipsis-h']} style={{color: 'red'}}/>&nbsp;
                                <FontAwesomeIcon icon={['fas', 'ellipsis-h']} style={{color: 'red'}}/>
                            </div>
                        </h5>
                        <p className="text-primary" style={textStyle}>
                            <FontAwesomeIcon icon={Icons.COMPANY_INFO_1} style={iconStyle} className="service-icon" mask="square-full" inverse/>
                            <span><b>Tehnodidakta</b> is a road signalization production company, located in <b>Shtip, North Macedonia</b>,
                                It was founded back in <b>1992</b> by the mechanical engineer <b>Gjorgji Gjogjievski</b>, which over the <b>40 years of history</b> has become
                                the largest manufacturer of road signalization products in North Macedonia</span><br/><br/>
                            <span>The etymology of the company name comes from the greek words <b>(τέχνη - tékhnē, “skill”)</b> and <b>(διδάσκειν - didáskein, "to teach")</b> in
                                translation meaning <b>"to teach a skill"</b> </span>&nbsp;
                            <span>Over the years the company has evolved from using primitive tools & manual labour, to a large scale company with <b>semi-automated production
                                process utilizing heavy machinery and advanced tools</b>.</span>&nbsp;
                        </p>
                    </div>
                </div>
            </AnimateSection>
            <AnimateSection>
                <div className="col p-2 m-0 mb-3">
                    <div className="card p-3">
                        <h5 className="px-5" style={headerStyle}>
                            <span>Mission</span>
                            <div style={{display: 'inline-block', float: 'right'}}>
                                <FontAwesomeIcon icon={['fas', 'ellipsis-h']} style={{color: 'red'}}/>&nbsp;
                                <FontAwesomeIcon icon={['fas', 'ellipsis-h']} style={{color: 'red'}}/>&nbsp;
                                <FontAwesomeIcon icon={['fas', 'ellipsis-h']} style={{color: 'red'}}/>
                            </div>
                        </h5>
                        <p className="text-primary" style={textStyle}>
                            <FontAwesomeIcon icon={Icons.COMPANY_INFO_2} style={iconStyle} className="service-icon" mask="square-full" inverse/>
                            <span>The constant evolutionary process of the company is perpetuated in a direction to minimize manual production and
                                maximize effectiveness with process automation, while maintaining <b>high quality product</b>. This is primarily accomplished by the usage
                                of <b>high quality grade materials, advanced heavy machinery, contemporary tools and pragmatic production procedures</b>.</span><br/><br/>
                            <span>Currently we provide products & services for the markets in <b>North Macedonia, Serbia, Albania, Kosovo and Turkey</b>.</span>&nbsp;
                            <span>As the effectiveness of our production processes advance and improve, our mission is to spread the export of our products to
                                other markets as the <b>Balkans</b> and the <b>EU</b>.</span>&nbsp;
                        </p>
                    </div>
                </div>
            </AnimateSection>
            <AnimateSection>
                <div className="col p-2 m-0 mb-3">
                    <div className="card p-3">
                        <h5 className="px-5" style={headerStyle}>
                            <span>Vision</span>
                            <div style={{display: 'inline-block', float: 'right'}}>
                                <FontAwesomeIcon icon={['fas', 'ellipsis-h']} style={{color: 'red'}}/>&nbsp;
                                <FontAwesomeIcon icon={['fas', 'ellipsis-h']} style={{color: 'red'}}/>&nbsp;
                                <FontAwesomeIcon icon={['fas', 'ellipsis-h']} style={{color: 'red'}}/>
                            </div>
                        </h5>
                        <p className="text-primary" style={textStyle}>
                            <FontAwesomeIcon icon={Icons.COMPANY_INFO_3} style={iconStyle} className="service-icon" mask="square-full" inverse/>
                            <span>Our vision for the future is to constantly improve in every direction possible by inclusion of contemporary technologies as: &nbsp;
                                <b>computer software, electronics and artificial intelligence</b>.</span><br/><br/>
                            <span>Our <b>research and development department</b> innovates in terms of creating new high-tech product lines.
                                Inclusion of electronics, software, harvesting and usage of clean energy (solar cells) will produce technologically
                                advanced & improved road signalization products & services in the future.</span>&nbsp;
                            <span>.</span>
                        </p>
                    </div>
                </div>
            </AnimateSection>
            <AnimateSection>
                <div className="col p-2 m-0 mb-3">
                    <div className="card p-3">
                        <h5 className="px-5" style={headerStyle}>
                            <span>Technology</span>
                            <div style={{display: 'inline-block', float: 'right'}}>
                                <FontAwesomeIcon icon={['fas', 'ellipsis-h']} style={{color: 'red'}}/>&nbsp;
                                <FontAwesomeIcon icon={['fas', 'ellipsis-h']} style={{color: 'red'}}/>&nbsp;
                                <FontAwesomeIcon icon={['fas', 'ellipsis-h']} style={{color: 'red'}}/>
                            </div>
                        </h5>
                        <p className="text-primary" style={textStyle}>
                            <FontAwesomeIcon icon={Icons.COMPANY_INFO_4} style={iconStyle} className="service-icon" mask="square-full" inverse/>
                            <span>Most of our products are made of metal, so the production lines are based on usage of <b>heavy CNC machinery, mechanics, electric tools and graphics editing software</b>.</span><br/><br/>
                            <span>Some instances of machines that we use are: <b>CNC abkant pressers, metal & foil cutters, plotters, routers, metal welders, solderers & puncturers, air compressors, foil laminators, graphic printers, etc</b>.</span>&nbsp;
                            <span>Lately we incorporate electronics in high profile products for which we use dedicated electric tools.</span>
                        </p>
                    </div>
                </div>
            </AnimateSection>
            <AnimateSection>
                <div className="col p-2 m-0 mb-3">
                    <div className="card p-3">
                        <h5 className="px-5" style={headerStyle}>
                            <span>Certification</span>
                            <div style={{display: 'inline-block', float: 'right'}}>
                                <FontAwesomeIcon icon={['fas', 'ellipsis-h']} style={{color: 'red'}}/>&nbsp;
                                <FontAwesomeIcon icon={['fas', 'ellipsis-h']} style={{color: 'red'}}/>&nbsp;
                                <FontAwesomeIcon icon={['fas', 'ellipsis-h']} style={{color: 'red'}}/>
                            </div>
                        </h5>
                        <p className="text-primary" style={textStyle}>
                            <FontAwesomeIcon icon={Icons.COMPANY_INFO_5} style={iconStyle} className="service-icon" mask="square-full" inverse/>
                            <span><b>ISO certification</b> ensures that our company meets international standards for quality management, environmental management, information security management, or other specific areas.</span>&nbsp;
                            <span>Our company's <b>ISO certification (ISO 9001:2015 & ISO 14001:2015)</b> demonstrates a commitment to excellence, efficiency, and customer satisfaction.</span><br/><br/>
                            <span>Our company's <b>3M certification</b> signifies recognition from a global leader in innovation and quality of products.</span>
                        </p>
                    </div>
                </div>
            </AnimateSection>
            <AnimateSection>
                <div className="col p-2 m-0 mb-3">
                    <div className="card p-3">
                        <h5 className="px-5" style={headerStyle}>
                            <span>Factory</span>
                            <div style={{display: 'inline-block', float: 'right'}}>
                                <FontAwesomeIcon icon={['fas', 'ellipsis-h']} style={{color: 'red'}}/>&nbsp;
                                <FontAwesomeIcon icon={['fas', 'ellipsis-h']} style={{color: 'red'}}/>&nbsp;
                                <FontAwesomeIcon icon={['fas', 'ellipsis-h']} style={{color: 'red'}}/>
                            </div>
                        </h5>
                        <p className="text-primary" style={textStyle}>
                            <FontAwesomeIcon icon={Icons.COMPANY_INFO_6} style={iconStyle} className="service-icon" mask="square-full" inverse/>
                            <span>Welcome to our <b>state-of-the-art factory</b> dedicated to the production of metallurgy and road signalization products.</span><br/><br/>
                            <span>Situated in a strategic location, our facility combines <b>cutting-edge technology with skilled craftsmanship</b> to deliver high-quality products that meet the diverse needs of our customers.</span>&nbsp;
                            <span>At our production factory, <b>precision engineering meets innovation</b> to deliver solutions that enhance safety, efficiency, and sustainability on roads and highways.</span>
                        </p>
                    </div>
                </div>
            </AnimateSection>
        </div>
    </>
}

export default CompanyView;
