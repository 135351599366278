import OrderSummary from "./OrderSummary";
import ProductCartCard from "./ProductCartCard";
import StringTool from "../../tools/StringTool";

const OrderDetail = ({order}) => {

    const billing = order?.billing;
    const shipping = order?.billingAsShipping ? order?.billing : order?.shipping;

    return <div className="row">
        {AddressView(billing, "Billing Address")}
        {AddressView(shipping, "Shipping Address")}
        {PaymentView(order)}
        <div className="col-lg-8">
            {order.products.map((product, i) =>
                <ProductCartCard key={i} product={product} editable={false} showRemoveButton={false}/> )}
        </div>
        <OrderSummary cart={order} showButton={false}/>
    </div>
}
export default OrderDetail;

const PaymentView = (order) => <div className="col-lg-4">
    <div className="card mb-4">
        <div className="card-header py-3 bg-white">
            <h5 className="mb-0">Payment Details</h5>
        </div>
        <div className="card-body">
            <ul className="list-group list-group-flush">
                <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                    ID
                    <span>{order?.id}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                    Card Number
                    <span>{order?.transaction?.cardNumber}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                    Card Expiration
                    <span>{order?.transaction?.cardExpirationMonth + '/' + order?.transaction?.cardExpirationYear}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                    Card Type
                    <span>{order?.transaction?.cardType}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                    Currency
                    <span>{order?.transaction?.currency}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                    Status
                    <span>{order?.status}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0">
                    <div>
                        <strong>Card Holder</strong>
                    </div>
                    <span><strong>{order?.transaction?.cardHolder}</strong></span>
                </li>

            </ul>
        </div>
    </div>
</div>

const AddressView = (address, title) => <div className="col-lg-4">
    <div className="card mb-4">
        <div className="card-header py-3 bg-white">
            <h5 className="mb-0">{title}</h5>
        </div>
        <div className="card-body">
            <ul className="list-group list-group-flush">
                <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                    Street Address
                    <span>{address?.address}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                    City
                    <span>{address?.city}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                    Zip Code
                    <span>{address?.zip}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                    State
                    <span>{address?.state}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                    Country
                    <span>{address?.country}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                    Email Address
                    <span>{StringTool.isNotBlank(address?.email) ? address?.email : 'n/a'}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0">
                    <div>
                        <strong>Name</strong>
                    </div>
                    <span><strong>{address?.firstName + ' ' + address?.lastName}</strong></span>
                </li>
            </ul>
        </div>
    </div>
</div>
