import {
    AUTHENTICATE,
    AUTHENTICATION_ERROR,
    AUTHENTICATION_INIT,
    AUTHENTICATION_LOGOUT,
    AUTHENTICATION_SUCCESS,
    AUTHORIZATION_ERROR,
    AUTHORIZATION_SUCCESS,
    AUTHORIZE,
    GET_CART_RESET,
    GET_USER,
    GET_USER_ERROR,
    GET_USER_SUCCESS,
    REGISTER,
    REGISTRATION_ERROR,
    REGISTRATION_SUCCESS,
    UPDATE_PASSWORD,
    UPDATE_PASSWORD_ERROR,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_USER,
    UPDATE_USER_ERROR,
    UPDATE_USER_SUCCESS
} from "./Types";
import AuthService from "../services/AuthService";

export const init = () => async (dispatch) => {
    let authentication = await AuthService.init();
    if (!authentication)
        authentication = await AuthService.authenticate(AuthService.USER_DEFAULT);
    dispatch({
        type: AUTHENTICATION_INIT,
        payload: authentication
    });
}

export const authenticate = (username, password) => async (dispatch) => {
    try {
        dispatch({
            type: AUTHENTICATE,
        });
        const res = await AuthService.authenticate({username, password});
        if (res?.status === 200)
            dispatch({
                type: AUTHENTICATION_SUCCESS,
                payload: res?.data,
            });
        else
            dispatch({
                type: AUTHENTICATION_ERROR,
                payload: res?.data,
            });
        return Promise.resolve(res?.data);
    } catch (error) {
        dispatch({
            type: AUTHENTICATION_ERROR,
            payload: error,
        });
        return Promise.reject(error);
    }
}

export const refresh = () => async (dispatch) => {
    try {
        dispatch({
            type: AUTHENTICATE,
        });
        const res = await AuthService.refresh();
        if (res?.status === 200)
            dispatch({
                type: AUTHENTICATION_SUCCESS,
                payload: res?.data,
            });
        else
            dispatch({
                type: AUTHENTICATION_ERROR,
                payload: res?.data,
            });
        return Promise.resolve(res?.data);
    } catch (error) {
        dispatch({
            type: AUTHENTICATION_ERROR,
            payload: error,
        });
        return Promise.reject(error);
    }
}

export const register = (email, username, password, firstName, lastName) => async (dispatch) => {
    try {
        dispatch({
            type: REGISTER,
        });
        const res = await AuthService.register({email, username, password, firstName, lastName});
        if (res?.status === 200)
            dispatch({
                type: REGISTRATION_SUCCESS,
                payload: res?.data,
            });
        else
            dispatch({
                type: REGISTRATION_ERROR,
                payload: res?.data,
            });
        return Promise.resolve(res?.data);
    } catch (error) {
        dispatch({
            type: REGISTRATION_ERROR,
            payload: error,
        });
        return Promise.reject(error);
    }
}

export const authorize = (username, password, grant) => async (dispatch) => {
    try {
        dispatch({
            type: AUTHORIZE
        });
        const res = await AuthService.authorize({username, password, grant});
        if (res?.status === 200)
            dispatch({
                type: AUTHORIZATION_SUCCESS,
                payload: res?.data,
            });
        else
            dispatch({
                type: AUTHORIZATION_ERROR,
                payload: res?.data,
            });
        return Promise.resolve(res?.data);
    } catch (error) {
        dispatch({
            type: AUTHORIZATION_ERROR,
            payload: error,
        });
        return Promise.reject(error);
    }
}

export const password = (username, code, password) => async (dispatch) => {
    try {
        dispatch({
            type: UPDATE_PASSWORD
        });
        const res = await AuthService.password({username, code, password});
        if (res?.status === 200)
            dispatch({
                type: UPDATE_PASSWORD_SUCCESS,
                payload: res?.data,
            });
        else
            dispatch({
                type: UPDATE_PASSWORD_ERROR,
                payload: res?.data,
            });
    } catch (error) {
        dispatch({
            type: UPDATE_PASSWORD_ERROR,
            payload: error,
        });
        return Promise.reject(error);
    }
}

export const logout = () => async (dispatch) => {
    dispatch({
        type: AUTHENTICATION_LOGOUT,
    });
    const res = await AuthService.logout();
    dispatch({
        type: GET_CART_RESET,
        payload: undefined
    });
    return Promise.resolve(res?.data);
}

export const profile = () => async (dispatch) => {
    try {
        dispatch({
            type: GET_USER
        });
        const res = await AuthService.user();
        if (res?.status === 200)
            dispatch({
                type: GET_USER_SUCCESS,
                payload: res?.data,
            });
        else
            dispatch({
                type: GET_USER_ERROR,
                payload: res?.data,
            });
        return Promise.resolve(res?.data);
    } catch (error) {
        dispatch({
            type: GET_USER_ERROR,
            payload: error,
        });
        return Promise.reject(error);
    }
}

export const update = (user) => async (dispatch) => {
    try {
        dispatch({
            type: UPDATE_USER
        });
        const res = await AuthService.update(user);
        if (res?.status === 200)
            dispatch({
                type: UPDATE_USER_SUCCESS,
                payload: res?.data,
            });
        else
            dispatch({
                type: UPDATE_USER_ERROR,
                payload: res?.data,
            });
    } catch (error) {
        dispatch({
            type: UPDATE_USER_ERROR,
            payload: error,
        });
        return Promise.reject(error);
    }
}
