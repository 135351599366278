export class Filter {

    property;
    operator;
    value;

    constructor(property, operator, value) {
        this.property = property;
        this.operator = operator;
        this.value = value;
    }

}

export class Sort {

    property;
    direction;

    constructor(property, direction) {
        this.property = property;
        this.direction = direction;
    }

}

export class Search {

    page;
    size;
    filter;
    sort;

    constructor(page, size, filter, sort) {
        this.page = page;
        this.size = size;
        this.filter = filter;
        this.sort = sort;
    }


}
