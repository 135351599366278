import Banner from "./Banner";
import ScrollToTopOnMount from "../../template/ScrollToTopOnMount";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {findFeaturedProducts} from "../../actions/Products";
import ProductCardV from "../products/ProductCardV";
import AnimateSection from "../animate/AnimateSection";

const LandingView = () => {

    const products = useSelector(state => state.products.featured);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!products || products?.length === 0)
            dispatch(findFeaturedProducts())
    }, [])

    return (
        <>
            <ScrollToTopOnMount/>
            <Banner/>
            <div className="d-flex flex-column bg-white py-4">
                <p className="text-center px-5">
                    Check out the online catalog of road signs, signals and
                    traffic equipment
                </p>
                <div className="d-flex justify-content-center">
                    <Link to="/products" className="btn btn-primary">
                        Browse Products
                    </Link>
                </div>
            </div>
            <h5 className="text-muted text-center mt-4 mb-3"/>
            <div className="container pb-5 pt-3 px-lg-5">
                <AnimateSection>
                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4 px-md-5">
                        {products?.map((product, i) => {
                            return <ProductCardV key={i} product={product} />
                        })}
                    </div>
                </AnimateSection>
            </div>
            <div className="d-flex flex-column bg-white py-4">
                <h5 className="text-center mb-3">Follow us on</h5>
                <div className="d-flex justify-content-center">
                    <a>
                        <FontAwesomeIcon icon={["fab", "facebook"]} size="2x"/>
                    </a>
                    <a className="ms-3">
                        <FontAwesomeIcon icon={["fab", "linkedin"]} size="2x"/>
                    </a>
                    <a className="ms-3">
                        <FontAwesomeIcon icon={["fab", "twitter"]} size="2x"/>
                    </a>
                    <a className="ms-3">
                        <FontAwesomeIcon icon={["fab", "instagram-square"]} size="2x"/>
                    </a>
                </div>
            </div>
        </>
    );
}

export default LandingView;
