import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link, useHistory, useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import Route, {Routes} from "../models/Route";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../actions/Auth";
import AuthService from "../services/AuthService";
import {getCart} from "../actions/Orders";
import Icons from "../models/Icons";
import useAnalytics from "../hooks/Analytics";
import {Event} from "../models/Analytics";

const Header = () => {

  const routes = [
    new Route("/company", "Company"),
    new Route("/products", "Products"),
    new Route("/services", "Services"),
    new Route("/contact", "Contact")
  ];
  const [openedDrawer, setOpenedDrawer] = useState(false)
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const analytics = useAnalytics();
  const cartCount = useSelector(state => state.orders.count);
  const cart = useSelector(state => state.orders.cart);
  const user = useSelector(state => state.auth.user);

  const toggleDrawer = () => {
    setOpenedDrawer(!openedDrawer);
  }

  const changeNav = (event) => {
    if (openedDrawer)
      setOpenedDrawer(false)
  }

  const signOut = (event) => {
    changeNav(event);
    const userId = user?.id
    dispatch(logout()).then(_ => {
      history.push(Routes.USER_SIGN_IN);
      analytics.event(Event.create(Event.SIGN_OUT, {
        user_id: userId
      }))
    });

  }

  const goToCart = (event) => {
    history.push(Routes.CART)
  }

  useEffect(() => {
    if (!user) return;
    if (!AuthService.isGuest && !cart) {
      dispatch(getCart());
    }
  }, [user])

  return (
    <header>
      <nav className="navbar fixed-top navbar-expand-lg navbar-light bg-white border-bottom">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/" onClick={changeNav}>
            <img src={process.env.PUBLIC_URL + "/images/logo.png"} style={{width: 30}} />
            <span className="ms-2 h5">Tehnodidakta</span>
          </Link>

          <div className={"navbar-collapse offcanvas-collapse " + (openedDrawer ? 'open' : '')}>
            <ul className="navbar-nav me-auto mb-lg-0">
              {routes.map((route, i) => {
                return <li key={i} className={"nav-item" + (location.pathname === route.path ? ' active' : '')}>
                  <Link to={route.path} className="nav-link" onClick={changeNav}>
                    {route.label}
                  </Link>
                </li>
              })}
            </ul>
            {cart && !AuthService.isGuest && <button type="button" className="btn btn-outline-dark me-3 d-none d-lg-inline" onClick={goToCart}>
              <FontAwesomeIcon icon={Icons.SHOPPING_CART} />
              <span className="ms-3 badge rounded-pill bg-dark">{cartCount || 0}</span>
            </button>}
            <ul className="navbar-nav mb-2 mb-lg-0">
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" data-toggle="dropdown" role="button"
                  data-bs-toggle="dropdown" aria-expanded="false">
                  <FontAwesomeIcon icon={Icons.USER_PROFILE_MENU} />
                </a>
                <ul className="dropdown-menu dropdown-menu-end"  aria-labelledby="userDropdown">
                  {!AuthService.isGuest && <li>
                    <Link to={Routes.USER_PROFILE} className="dropdown-item" onClick={changeNav}>
                      <FontAwesomeIcon icon={Icons.USER_PROFILE} style={{minWidth: 20}} /> &nbsp; Profile
                    </Link>
                  </li>}
                  {AuthService.isGuest && <li>
                    <Link to={Routes.USER_SIGN_IN} className="dropdown-item" onClick={changeNav}>
                      <FontAwesomeIcon icon={Icons.USER_SIGN_IN} /> &nbsp;Sign In
                    </Link>
                  </li>}
                  {!AuthService.isGuest && <li>
                    <Link to={Routes.USER_SIGN_IN} className="dropdown-item" onClick={signOut}>
                      <FontAwesomeIcon icon={Icons.USER_SIGN_OUT} /> &nbsp; Sign Out
                    </Link>
                  </li>}
                  {AuthService.isGuest && <li>
                    <Link to={Routes.USER_SIGN_UP} className="dropdown-item" onClick={changeNav}>
                      <FontAwesomeIcon icon={Icons.USER_SIGN_UP} style={{minWidth: 20}} /> &nbsp; Sign Up
                    </Link>
                  </li>}
                </ul>
              </li>
            </ul>
          </div>

          <div className="d-inline-block d-lg-none">
            {cart && <button type="button" className="btn btn-outline-dark" onClick={goToCart}>
                <FontAwesomeIcon icon={["fas", "shopping-cart"]} />
                <span className="ms-3 badge rounded-pill bg-dark">{cartCount || 0}</span>
            </button>}
            <button className="navbar-toggler p-0 border-0 ms-3" type="button" onClick={toggleDrawer}>
              <span className="navbar-toggler-icon"/>
            </button>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
