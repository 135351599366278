import Patterns from "../../models/Patterns";

export const emailField = {
    name: 'email',
    label: 'E-Mail',
    type: 'text',
    id: 'email',
    placeholder: 'E-Mail Address',
    validation: {
        required: {
            value: true,
            message: '*',
        },
        maxLength: {
            value: 64,
            message: '64 max',
        },
        minLength: {
            value: 4,
            message: '4 min',
        },
        pattern: {
            value: Patterns.EMAIL,
            message: "bad format"
        }
    },
    className: "form-control form-control-lg"
};

export const nameField = {
    name: 'name',
    label: 'Full Name',
    type: 'text',
    id: 'name',
    placeholder: 'Full Name',
    validation: {
        required: {
            value: true,
            message: '*',
        },
        maxLength: {
            value: 50,
            message: '50 max',
        },
        minLength: {
            value: 4,
            message: '4 min',
        },
        pattern: {
            value: Patterns.FULL_NAME,
            message: 'bad format'
        }
    },
    className: "form-control form-control-lg"
};

export const subjectField = {
    name: 'subject',
    label: 'Subject',
    type: 'text',
    id: 'subject',
    placeholder: 'Subject',
    validation: {
        required: {
            value: true,
            message: '*',
        },
        maxLength: {
            value: 100,
            message: '100 max',
        },
        minLength: {
            value: 3,
            message: '3 min',
        }
    },
    className: "form-control form-control-lg"
};

export const messageField = {
    name: 'message',
    label: 'Message',
    type: 'textarea',
    id: 'message',
    placeholder: 'Message',
    validation: {
        required: {
            value: true,
            message: '*',
        },
        maxLength: {
            value: 8096,
            message: '8096 max',
        },
        minLength: {
            value: 3,
            message: '3 min'
        }
    },
    className: "form-control form-control-lg"
};
