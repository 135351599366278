import {
    FIND_FEATURED_PRODUCTS, FIND_FEATURED_PRODUCTS_ERROR, FIND_FEATURED_PRODUCTS_SUCCESS,
    FIND_PRODUCT, FIND_PRODUCT_ERROR, FIND_PRODUCT_FILTERS, FIND_PRODUCT_FILTERS_ERROR, FIND_PRODUCT_FILTERS_SUCCESS,
    FIND_PRODUCT_SUCCESS, FIND_RELATED_PRODUCTS, FIND_RELATED_PRODUCTS_ERROR, FIND_RELATED_PRODUCTS_SUCCESS,
    SEARCH_PRODUCTS,
    SEARCH_PRODUCTS_ERROR,
    SEARCH_PRODUCTS_SUCCESS, SET_PRODUCT
} from "../actions/Types";
import State from "../models/State";

const initialState = new State();

const ProductReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch(type) {
        case SEARCH_PRODUCTS:
            return {...state, loading: true};
        case SEARCH_PRODUCTS_SUCCESS:
            return {...state, loading: false, data: payload};
        case SEARCH_PRODUCTS_ERROR:
            return {...state, loading: false, error: payload};
        case FIND_PRODUCT:
            return {...state, loading: true, product: null};
        case FIND_PRODUCT_SUCCESS:
            return {...state, loading: false, product: payload};
        case FIND_PRODUCT_ERROR:
            return {...state, loading: false, error: payload};
        case FIND_PRODUCT_FILTERS:
            return {...state, loading: true};
        case FIND_PRODUCT_FILTERS_SUCCESS:
            return {...state, loading: false, filters: payload};
        case FIND_PRODUCT_FILTERS_ERROR:
            return {...state, loading: false, error: payload};
        case FIND_RELATED_PRODUCTS:
            return {...state, loading: false, related: []};
        case FIND_RELATED_PRODUCTS_SUCCESS:
            return {...state, loading: false, related: payload.data};
        case FIND_RELATED_PRODUCTS_ERROR:
            return {...state, loading: false, error: payload, related: []};
        case FIND_FEATURED_PRODUCTS:
            return {...state, loading: false, featured: []};
        case FIND_FEATURED_PRODUCTS_SUCCESS:
            return {...state, loading: false, featured: payload.data};
        case FIND_FEATURED_PRODUCTS_ERROR:
            return {...state, loading: false, error: payload, featured: []};
        case SET_PRODUCT:
            return {...state, product: payload, loading: false };
        default:
            return state;
    }
};

export default ProductReducer;
