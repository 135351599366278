import {HIDE_MODAL, SHOW_MODAL} from "./Types";

export const showModal = (options) => async (dispatch) => {
    dispatch({
        type: SHOW_MODAL,
        payload: options
    });
    return Promise.resolve();
}

export const hideModal = () => async (dispatch) => {
    dispatch({
        type: HIDE_MODAL
    })
    return Promise.resolve();
}
