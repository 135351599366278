import {
    FIND_FEATURED_PRODUCTS, FIND_FEATURED_PRODUCTS_ERROR, FIND_FEATURED_PRODUCTS_SUCCESS,
    FIND_PRODUCT, FIND_PRODUCT_ERROR, FIND_PRODUCT_FILTERS, FIND_PRODUCT_FILTERS_ERROR, FIND_PRODUCT_FILTERS_SUCCESS,
    FIND_PRODUCT_SUCCESS, FIND_RELATED_PRODUCTS, FIND_RELATED_PRODUCTS_ERROR, FIND_RELATED_PRODUCTS_SUCCESS,
    SEARCH_PRODUCTS,
    SEARCH_PRODUCTS_ERROR,
    SEARCH_PRODUCTS_SUCCESS
} from "./Types";
import ProductService from "../services/ProductService";

export const searchProducts = ({page, size, filter, sort}) => async (dispatch) => {
    try {
        dispatch({
            type: SEARCH_PRODUCTS,
        });
        const res = await ProductService.search(page, size, filter, sort);
        if (res?.status === 200)
            dispatch({
                type: SEARCH_PRODUCTS_SUCCESS,
                payload: res?.data,
            });
        else
            dispatch({
                type: SEARCH_PRODUCTS_ERROR,
                payload: res?.data,
            });
        return Promise.resolve(res?.data);
    } catch (error) {
        console.error(error);
        dispatch({
            type: SEARCH_PRODUCTS_ERROR,
            payload: error,
        });
        return Promise.reject(error);
    }
}

export const findProduct = (id) => async (dispatch) => {
    try {
        dispatch({
            type: FIND_PRODUCT,
            payload: id
        });
        const res = await ProductService.findById(id);
        if (res?.status === 200)
            dispatch({
                type: FIND_PRODUCT_SUCCESS,
                payload: res?.data,
            });
        else
            dispatch({
                type: FIND_PRODUCT_ERROR,
                payload: res?.data,
            });
        return Promise.resolve(res?.data);
    } catch (error) {
        console.error(error);
        dispatch({
            type: FIND_PRODUCT_ERROR,
            payload: error,
        });
        return Promise.reject(error);
    }
}

export const findRelatedProducts = (product) => async (dispatch) => {
    try {
        dispatch({
            type: FIND_RELATED_PRODUCTS,
            payload: product.category.id
        })
        const res = await ProductService.findRelated(product.category.id, product.id);
        if (res?.status === 200)
            dispatch({
                type: FIND_RELATED_PRODUCTS_SUCCESS,
                payload: res?.data,
            });
        else
            dispatch({
                type: FIND_RELATED_PRODUCTS_ERROR,
                payload: res?.data,
            });
        return Promise.resolve(res?.data);
    } catch (error) {
        console.error(error);
        dispatch({
            type: FIND_RELATED_PRODUCTS_ERROR,
            payload: error,
        });
        return Promise.reject(error);
    }
}

export const findFeaturedProducts = () => async (dispatch) => {
    try {
        dispatch({
            type: FIND_FEATURED_PRODUCTS
        })
        const res = await ProductService.findFeatured();
        if (res?.status === 200)
            dispatch({
                type: FIND_FEATURED_PRODUCTS_SUCCESS,
                payload: res?.data,
            });
        else
            dispatch({
                type: FIND_FEATURED_PRODUCTS_ERROR,
                payload: res?.data,
            });
        return Promise.resolve(res?.data);
    } catch (error) {
        console.error(error);
        dispatch({
            type: FIND_FEATURED_PRODUCTS_ERROR,
            payload: error,
        });
        return Promise.reject(error);
    }
}

export const findFilters = () => async (dispatch) => {
    try {
        dispatch({
            type: FIND_PRODUCT_FILTERS
        });
        const res = await ProductService.findFilters();
        if (res?.status === 200)
            dispatch({
                type: FIND_PRODUCT_FILTERS_SUCCESS,
                payload: res?.data,
            });
        else
            dispatch({
                type: FIND_PRODUCT_FILTERS_ERROR,
                payload: res?.data,
            });
        return Promise.resolve(res?.data);
    } catch (error) {
        dispatch({
            type: FIND_PRODUCT_FILTERS_ERROR,
            payload: error,
        });
        return Promise.reject(error);
    }
}
