import Patterns from "../../models/Patterns";

export const firstNameField = {
    name: 'firstName',
    label: 'First Name',
    type: 'text',
    id: 'firstName',
    placeholder: 'First Name',
    validation: {
        required: {
            value: true,
            message: '*',
        },
        maxLength: {
            value: 64,
            message: '64 max',
        },
        minLength: {
            value: 2,
            message: '2 min',
        },
        pattern: {
            value: Patterns.NAME_PART,
            message: 'bad format',
        },
    },
    className: "form-control form-control-lg"
};

export const lastNameField = {
    name: 'lastName',
    label: 'Last Name',
    type: 'text',
    id: 'lastName',
    placeholder: 'Last Name',
    validation: {
        required: {
            value: true,
            message: '*',
        },
        maxLength: {
            value: 64,
            message: '64 max',
        },
        minLength: {
            value: 2,
            message: '2 min',
        },
        pattern: {
            value: Patterns.NAME_PART,
            message: 'bad format',
        },
    },
    className: "form-control form-control-lg"
};

export const usernameField = {
    name: 'username',
    label: 'username',
    type: 'text',
    id: 'username',
    placeholder: 'Username',
    validation: {
        required: {
            value: true,
            message: '*',
        },
        maxLength: {
            value: 64,
            message: '64 max',
        },
        minLength: {
            value: 4,
            message: '4 min',
        },
        pattern: {
            value: Patterns.USERNAME,
            message: 'bad format',
        },
    },
    className: "form-control form-control-lg"
};

export const emailField = {
    name: 'email',
    label: 'email',
    type: 'text',
    id: 'email',
    placeholder: 'E-Mail',
    validation: {
        required: {
            value: true,
            message: '*',
        },
        maxLength: {
            value: 64,
            message: '64 max',
        },
        minLength: {
            value: 4,
            message: '4 min',
        },
        pattern: {
            value: Patterns.EMAIL,
            message: "bad format"
        }
    },
    className: "form-control form-control-lg"
};

export const passwordField = {
    name: 'password',
    label: 'password',
    type: 'password',
    id: 'password',
    placeholder: 'Password',
    validation: {
        required: {
            value: true,
            message: '*',
        },
        minLength: {
            value: 8,
            message: '8 min',
        },
        maxLength: {
            value: 32,
            message: '32 max',
        },
        pattern: {
            value: Patterns.PASSWORD,
            message: 'bad format',
        },
    },
    className: "form-control form-control-lg"
};

export const termsAcceptField = {
    name: 'termsAccept',
    label: 'termsAccept',
    type: 'checkbox',
    id: 'termsAccept',
    placeholder: 'Accept Terms & Conditions',
    validation: {
        required: {
            value: true,
            message: '*',
        }
    },
    className: "form-check-input me-2"
};

