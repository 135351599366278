import ProductTool from "./ProductTool";

export default class OrderTool {

    #order;

    constructor(order) {
        this.#order = order;
    }

    productsAmount() {
        return this.#order?.products.reduce((a, p, i) => {
            a += p.quantity * (p.product?.price || 0);
            return parseFloat(a);
        }, 0);
    }

    shippingAmount() {
        return this.#order?.products.reduce((a, p, i) => {
            a += p.quantity * (p.product?.shipping || 0)
            return parseFloat(a);
        }, 0);
    }

    discountAmount() {
        return this.#order?.products.reduce((a, p, i) => {
            a += p.quantity * (new ProductTool(p.product).discountPrice() || 0)
            return parseFloat(a);
        }, 0);
    }

    taxAmount() {
        return this.#order?.products.reduce((a, p, i) => {
            const diff = new ProductTool(p.product).taxPrice() ;
            a += p.quantity * (diff || 0)
            return parseFloat(a);
        }, 0);
    }

    totalAmount() {
        return this.#order?.products.reduce((a, p, i) => {
            const tool = new ProductTool(p.product);
            const diff = tool.price() - tool.discountPrice() + tool.taxPrice() + tool.shipping();
            a += p.quantity * (diff || 0)
            return parseFloat(a);
        }, 0);
    }

    count() {
        return this.#order?.products.reduce((acc, item, idx) => {
            acc += item.quantity;
            return acc;
        }, 0);
    }
}
