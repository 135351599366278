import Patterns from "../../models/Patterns";

export const firstNameField = {
    name: 'firstName',
    label: 'First Name',
    type: 'text',
    id: 'firstName',
    placeholder: 'First Name',
    validation: {
        required: {
            value: true,
            message: '*',
        },
        minLength: {
            value: 2,
            message: '2 min',
        },
        maxLength: {
            value: 32,
            message: '32 max',
        },
        pattern: {
            value: Patterns.NAME_PART,
            message: "invalid"
        }
    },
    className: "form-control form-control-lg"
};

export const lastNameField = {
    name: 'lastName',
    label: 'Last Name',
    type: 'text',
    id: 'lastName',
    placeholder: 'Last Name',
    validation: {
        required: {
            value: true,
            message: '*',
        },
        maxLength: {
            value: 20,
            message: '30 max',
        },
        minLength: {
            value: 2,
            message: '4 min',
        },
        pattern: {
            value: Patterns.NAME_PART,
            message: "invalid"
        }
    },
    className: "form-control form-control-lg"
};

export const birthdayField = {
    name: 'birthday',
    label: 'Birthday',
    type: 'text',
    id: 'birthday',
    placeholder: 'Birthday (YYYY-MM-DD)',
    validation: {
        pattern: {
            value: Patterns.BIRTHDAY,
            message: "invalid"
        }
    },
    className: "form-control form-control-lg"
};

export const phoneField = {
    name: 'phone',
    label: 'Phone',
    type: 'number',
    id: 'phone',
    placeholder: 'Phone (code|prefix|number)',
    validation: {
        pattern: {
            value: Patterns.PHONE,
            message: "invalid"
        }
    },
    className: "form-control form-control-lg"
};
