import {client, hostedFields} from "braintree-web";

export default class PaymentTool {

    token;

    constructor(token) {
        this.token = token;
    }

    async pay({transaction, billing}) {
        const authorization = { authorization: this.token };
        const instance = await client?.create(authorization);
        const options = {
            client: instance,
            authorization: this.token,
            fields: {
                cardholderName: {
                    selector: "#cardHolder",
                    prefill: transaction.cardHolder,
                },
                number: {
                    selector: "#cardNumber",
                    prefill: transaction.cardNumber,
                },
                cvv: {
                    selector: "#cardCvv",
                    prefill: transaction.cardCvv,
                },
                expirationMonth: {
                    selector: "#cardExpirationMonth",
                    prefill: transaction.cardExpirationMonth,
                },
                expirationYear: {
                    selector: "#cardExpirationYear",
                    prefill: transaction.cardExpirationYear,
                },
            },
        };
        const fields = await hostedFields?.create(options);
        const tokenize = {
            cardholderName: transaction.cardHolder,
            billingAddress: {
                streetAddress: billing.address,
                extendedAddress: billing.address2,
                locality: billing.city,
                region: billing.state,
                postalCode: billing.zip,
                countryName: billing.country,
            },
        };
        try {
            const token = await fields?.tokenize(tokenize);
            fields.teardown(() => undefined);
            return {
                nonce: token?.nonce,
                type: token?.type,
                cardType: token?.details.cardType,
            };
        } catch (e) {
            fields.teardown(() => undefined);
            throw e;
        }
    }

}
