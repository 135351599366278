export default class Route {

    path = "";
    label = "";

    constructor(path, label) {
        this.path = path;
        this.label = label;
    }

}

export class Routes {
    static ROOT = "/";
    static PRODUCTS = "/products";
    static PRODUCT = "/products/:id";
    static CONTACT = "/contact";
    static COMPANY = "/company";
    static SERVICES = "/services";
    static CART = "/cart";
    static CHECKOUT = "/cart/checkout";
    static CHECKOUT_SHIPPING = "/cart/checkout/shipping";
    static CHECKOUT_BILLING = "/cart/checkout/billing";
    static CHECKOUT_PAYMENT = "/cart/checkout/payment";
    static CHECKOUT_REVIEW = "/cart/checkout/review";
    static ORDER = "/orders/:id";
    static USER_SIGN_IN = "/user/login";
    static USER_SIGN_UP = "/user/register";
    static USER_PASS_RESET = "/user/password/reset";
    static USER_PROFILE = "/user/profile";
}
