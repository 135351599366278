class FormTool {

    static findErrors(errors, name) {
        return Object.keys(errors).filter(key => key.includes(name))
            .reduce((cur, key) => {
                return Object.assign(cur, {error: errors[key]})
            }, []);
    }

    static isInvalid(error) {
        return Object.keys(error).length > 0;
    }

}

export default FormTool;
