import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useFormContext} from "react-hook-form";
import FormTool from "../../tools/FormTool";
import PropTypes from "prop-types";
import {InputError} from "./Input";

const TextArea = ({
                      name,
                      label,
                      id,
                      placeholder,
                      className,
                      validation,
                      icon,
                      showLabel,
                      onChange,
                      value,
                      disabled,
                      style
                  }) => {

    const borderRegular = '1px solid rgb(227,227,227)';
    const borderError = "1px solid red";
    const {register, setValue, formState: {errors}} = useFormContext()
    const inputError = FormTool.findErrors(errors, name)
    const isInvalid = FormTool.isInvalid(inputError)
    if (validation?.required)
        validation.required.value = !!!disabled;

    return (
        <div className="flex flex-col w-full gap-2 position-relative">
            <div className="flex justify-between position-absolute" style={{color: 'red', right: 0, zIndex: 1111}}>
                {isInvalid && <InputError message={inputError.error.message} key={inputError.error.message}/>}
            </div>
            {showLabel && <label htmlFor={id}>{label}</label>}
            <div className="input-group input-group-lg" style={{
                height: 'auto',
                border: (isInvalid ? borderError : borderRegular)
            }}>
                {icon && <div className="input-group-prepend">
                    <span className="input-group-text">
                        <FontAwesomeIcon icon={icon}/>
                    </span>
                </div>}
                <textarea {...register(name, {...validation})}
                       name={name}
                       id={id}
                       className={className}
                       placeholder={placeholder}
                       value={value}
                       disabled={disabled}
                       style={style}
                       onChange={(e) => {
                           onChange(e);
                           setValue(name, e.target.value);
                       }}/>
            </div>
        </div>
    )
}

export default TextArea;

TextArea.propTypes = {
    showLabel: PropTypes.bool,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    icon: PropTypes.any,
    value: PropTypes.any,
    validation: PropTypes.any,
    placeholder: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
}

TextArea.defaultProps = {
    showLabel: false,
    placeholder: 'Insert',
    onChange: (e) => {
        // console.info(e)
    }
}
