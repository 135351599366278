import {useDispatch, useSelector} from "react-redux";
import {hideModal} from "../../actions/Modal";

const ModalDialog = () => {

    const dispatch = useDispatch();
    const modal = useSelector(state => state.modal.data);

    const close = () => {
        dispatch(hideModal());
    }

    return (<div className="modal" tabIndex="-1" style={{display: modal ? 'block' : 'none'}}>
        <div className="modal-dialog modal-sm modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">{modal?.title}</h5>
                    <button type="button" className="btn-close" aria-label="Close" onClick={close}/>
                </div>
                <div className="modal-body" style={{textAlign: 'center'}}>
                    {modal?.content}
                </div>
                <div className="modal-footer" onClick={close}>
                    {modal?.controls}
                </div>
            </div>
        </div>
    </div>)
}
export default ModalDialog;
