import { combineReducers } from "redux";
import Products from "./Products";
import Categories from "./Categories";
import Auth from "./Auth";
import Orders from "./Orders";
import Modal from "./Modal";

export default combineReducers({
    auth: Auth,
    categories: Categories,
    products: Products,
    orders: Orders,
    modal: Modal
});
