export default class Patterns {
    static FULL_NAME = /^[A-Z][a-z]+[\s+][A-Z][a-z]+$/;
    static NAME_PART = /^[A-Z]{1}[a-z]+$/;
    static CREDIT_CARD_NUMBER = /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\\d{3})\\d{11})$/;
    static CREDIT_CARD_CVV = /^[\d+]{3}|[\d+]{4}$/;
    static CREDIT_CARD_EXPIRATION = /^[\d+]{2,4}$/;
    static CREDIT_CARD_EXPIRATION_MONTH = /^(0?[1-9]|1[012])$/;
    static CREDIT_CARD_EXPIRATION_YEAR = /^(20)\d{2}$/;
    static EMAIL = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    static BIRTHDAY = /^[0-9]{4,}-[0-9]{2,}-[0-9]{2,}$/;
    static PHONE = /^\d{11,12}$/;
    static COUNTRY = /([A-Z]?[a-zA-Z'`.\s]*)/;
    static CODE = /^\d{6}$/;
    static ZIP = /^\d{4,6}$/;
    static USERNAME = /^[a-zA-Z0-9_-]+$/
    static USERNAME_OR_EMAIL = /^([A-Z0-9._-]+@[A-Z0-9.-]+\.[A-Z]{2,})|([a-zA-Z0-9_-]+)$/
    static PASSWORD = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*_-]).{8,32}$/

    static ERROR = 'bad format';
}
