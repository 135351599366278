import http from "../http/Axios";
import {Filter, Search, Sort} from "../models/Search";

class ProductService {

    search(page, size, filters, sorts) {
        const request = new Search(page, size, filters, sorts)
        return http.post("/data/http/products", request)
    }

    findById(id) {
        return http.get("/data/http/products/" + id)
    }

    findRelated(categoryId, excludeId) {
        const filters = [new Filter("category.id", "eq", categoryId),
            new Filter("id", "neq", excludeId)];
        const sorts = [new Sort("name", 1)];
        const request = new Search(1, 4, filters, sorts);
        return http.post("/data/http/products", request);
    }

    findFilters() {
        return http.get("/data/http/filters");
    }

    findFeatured() {
        const filters = [new Filter("featured", "eq", true),
            new Filter("productId", "null")];
        const sorts = [new Sort("timeModified", -1)];
        const request = new Search(1, 8, filters, sorts);
        return http.post("/data/http/products", request);
    }

}

const service = new ProductService();
export default service;
