import useImageLoader from "../../hooks/ImageLoader";

const ImageLoader = () => {
    const images = [
        process.env.PUBLIC_URL + "/images/banner_12.png",
        process.env.PUBLIC_URL + "/images/banner_08.png",
        process.env.PUBLIC_URL + "/images/banner_07.png",
        process.env.PUBLIC_URL + "/images/banner_06.png",
        process.env.PUBLIC_URL + "/images/banner_10.png",
        process.env.PUBLIC_URL + "/images/banner_09.png"
    ]
    useImageLoader(images);
    return <></>;
}

export default ImageLoader;
