import {
    AUTHENTICATE,
    AUTHENTICATION_ERROR,
    AUTHENTICATION_INIT,
    AUTHENTICATION_LOGOUT,
    AUTHENTICATION_SUCCESS,
    AUTHORIZATION_ERROR,
    AUTHORIZATION_SUCCESS,
    AUTHORIZE,
    GET_USER,
    GET_USER_ERROR,
    GET_USER_SUCCESS,
    REGISTER,
    REGISTRATION_ERROR,
    REGISTRATION_SUCCESS,
    UPDATE_PASSWORD,
    UPDATE_PASSWORD_ERROR,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_USER,
    UPDATE_USER_ERROR,
    UPDATE_USER_SUCCESS
} from "../actions/Types";
import AuthService from "../services/AuthService";

const initialState = {};

const AuthReducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case AUTHENTICATE:
            return {...state, loading: true};
        case AUTHENTICATION_INIT:
            return {...state, loading: false, user: payload};
        case AUTHENTICATION_SUCCESS:
            localStorage.setItem(AuthService.USER_AUTH, JSON.stringify(payload));
            return {...state, loading: false, user: payload, error: null};
        case AUTHENTICATION_ERROR:
            return {...state, loading: false, error: payload, user: null};
        case AUTHENTICATION_LOGOUT:
            return {...state, user: null};
        case AUTHORIZE:
            return {...state, loading: true}
        case AUTHORIZATION_SUCCESS:
        case AUTHORIZATION_ERROR:
            return {...state, loading: false}
        case REGISTER:
            return {...state, loading: true};
        case REGISTRATION_SUCCESS:
            return {...state, loading: false, user: payload, error: null};
        case REGISTRATION_ERROR:
            return {...state, loading: false, error: payload, user: null};
        case UPDATE_PASSWORD:
            return {...state, loading: true}
        case UPDATE_PASSWORD_SUCCESS:
            return {...state, loading: false, error: null}
        case UPDATE_PASSWORD_ERROR:
            return {...state, loading: false, error: payload}
        case GET_USER:
            return {...state, loading: true}
        case GET_USER_SUCCESS:
            return {...state, loading: false, profile: payload, error: null}
        case GET_USER_ERROR:
            return {...state, loading: false, error: payload, profile: null}
        case UPDATE_USER:
            return {...state, loading: true}
        case UPDATE_USER_SUCCESS:
            return {...state, loading: false, profile: payload, error: null}
        case UPDATE_USER_ERROR:
            return {...state, loading: false, error: payload, profile: null}
        default:
            return state;
    }
}

export default AuthReducer;
