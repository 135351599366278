export const SEARCH_CATEGORIES = "SEARCH_CATEGORIES";
export const SEARCH_CATEGORIES_SUCCESS = "SEARCH_CATEGORIES_SUCCESS";
export const SEARCH_CATEGORIES_ERROR = "SEARCH_CATEGORIES_ERROR";

export const SEARCH_PRODUCTS = "SEARCH_PRODUCTS";
export const SEARCH_PRODUCTS_SUCCESS = "SEARCH_PRODUCTS_SUCCESS";
export const SEARCH_PRODUCTS_ERROR = "SEARCH_PRODUCTS_ERROR";

export const FIND_PRODUCT = "FIND_PRODUCT";
export const FIND_PRODUCT_SUCCESS = "FIND_PRODUCT_SUCCESS";
export const FIND_PRODUCT_ERROR = "FIND_PRODUCT_ERROR";

export const FIND_PRODUCT_FILTERS = "FIND_PRODUCT_FILTERS";
export const FIND_PRODUCT_FILTERS_SUCCESS = "FIND_PRODUCT_FILTERS_SUCCESS";
export const FIND_PRODUCT_FILTERS_ERROR = "FIND_PRODUCT_FILTERS_ERROR";

export const FIND_RELATED_PRODUCTS = "FIND_RELATED_PRODUCTS";
export const FIND_RELATED_PRODUCTS_SUCCESS = "FIND_RELATED_PRODUCTS_SUCCESS";
export const FIND_RELATED_PRODUCTS_ERROR = "FIND_RELATED_PRODUCTS_ERROR";

export const FIND_FEATURED_PRODUCTS = "FIND_FEATURED_PRODUCTS";
export const FIND_FEATURED_PRODUCTS_SUCCESS = "FIND_FEATURED_PRODUCTS_SUCCESS";
export const FIND_FEATURED_PRODUCTS_ERROR = "FIND_FEATURED_PRODUCTS_ERROR";

export const SET_PRODUCT = "SET_PRODUCT";

export const AUTHENTICATE = "AUTHENTICATE";
export const AUTHENTICATION_INIT = "AUTHENTICATION_INIT";
export const AUTHENTICATION_SUCCESS = "AUTHENTICATION_SUCCESS";
export const AUTHENTICATION_ERROR = "AUTHENTICATION_ERROR";
export const AUTHENTICATION_LOGOUT = "AUTHENTICATION_LOGOUT";

export const AUTHORIZE = "AUTHORIZE";
export const AUTHORIZATION_SUCCESS = "AUTHORIZATION_SUCCESS";
export const AUTHORIZATION_ERROR = "AUTHORIZATION_ERROR";

export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_ERROR = "UPDATE_PASSWORD_ERROR";

export const REGISTER = "REGISTER";
export const REGISTRATION_SUCCESS = "REGISTRATION_SUCCESS";
export const REGISTRATION_ERROR = "REGISTRATION_ERROR";

export const GET_CART = "GET_CART";
export const GET_CART_SUCCESS = "GET_CART_SUCCESS";
export const GET_CART_ERROR = "GET_CART_ERROR";
export const GET_CART_COUNT = "GET_CART_COUNT";
export const GET_CART_COUNT_SUCCESS = "GET_CART_COUNT_SUCCESS";
export const GET_CART_COUNT_ERROR = "GET_CART_COUNT_ERROR";
export const GET_CART_RESET = "GET_CART_RESET";
export const CREATE_CART_ITEM = "CREATE_CART_ITEM";
export const CREATE_CART_ITEM_SUCCESS = "CREATE_CART_ITEM_SUCCESS";
export const CREATE_CART_ITEM_ERROR = "CREATE_CART_ITEM_ERROR";
export const UPDATE_CART_ITEM = "UPDATE_CART_ITEM";
export const UPDATE_CART_ITEM_SUCCESS = "UPDATE_CART_ITEM_SUCCESS";
export const UPDATE_CART_ITEM_ERROR = "UPDATE_CART_ITEM_ERROR";
export const REMOVE_CART_ITEM = "REMOVE_CART_ITEM";
export const REMOVE_CART_ITEM_SUCCESS = "REMOVE_CART_ITEM_SUCCESS";
export const REMOVE_CART_ITEM_ERROR = "REMOVE_CART_ITEM_ERROR";

export const GET_PAYMENT_TOKEN = "GET_PAYMENT_TOKEN";
export const GET_PAYMENT_TOKEN_SUCCESS = "GET_PAYMENT_TOKEN_SUCCESS";
export const GET_PAYMENT_TOKEN_ERROR = "GET_PAYMENT_TOKEN_ERROR";

export const CHECKOUT = "CHECKOUT";
export const CHECKOUT_SUCCESS = "CHECKOUT_SUCCESS";
export const CHECKOUT_ERROR = "CHECKOUT_ERROR";

export const FIND_ORDER = "FIND_ORDER";
export const FIND_ORDER_SUCCESS = "FIND_ORDER_SUCCESS";
export const FIND_ORDER_ERROR = "FIND_ORDER_ERROR";
export const SET_ORDER = "SET_ORDER";

export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";

export const FIND_TRANSACTIONS = "FIND_ORDERS";
export const FIND_TRANSACTIONS_SUCCESS = "FIND_ORDERS_SUCCESS";
export const FIND_TRANSACTIONS_ERROR = "FIND_ORDERS_ERROR";

export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_ERROR = "GET_USER_ERROR";

export const UPDATE_USER = "UPDATE_USER_PROFILE";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";

export const SEND_MESSAGE = "SEND_MESSAGE";
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS";
export const SEND_MESSAGE_ERROR = "SEND_MESSAGE_ERROR";
