import {useEffect, useRef, useState} from "react";
import ImageTool from "../tools/ImageTool";

const preloaded = [];

const useImageLoader = (images) => {

    let cancelled = useRef(false);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (loaded) return;
        preload();
        return () => {
            cancelled.current = true;
        }
    }, [images]);

    const preload = async () => {

        if (cancelled.current) return;

        const promises = []
        for (const i of images) {
            if (preloaded.indexOf(i) > -1) continue;
                promises.push(ImageTool.preload(i))
        }
        await Promise.all(promises);

        if (cancelled.current) return;
        setLoaded(true);

        return promises;
    }

}

export default useImageLoader;
