import Header from "./Header";
import Content from "./Content";
import Footer from "./Footer";
import ModalDialog from "../components/modal/ModalDialog";
import Loader from "../components/loader/Loader";
import ScrollToTop from "./ScrollToTop";

const Template = (props) => {
    return (
        <>
            <Header/>
            <Content>{props.children}</Content>
            <Footer/>
            <ModalDialog/>
            <Loader width={100} height={100}/>
            <ScrollToTop/>
        </>
    );
}

export default Template;
