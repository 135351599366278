import {ColorRing, Oval} from "react-loader-spinner";
import {useSelector} from "react-redux";

const Loader = ({width, height, loading}) => {

    const state = useSelector(state => state);

    const isLoading = () => {
        return state.auth.loading || state.orders.loading || state.products.loading || state.categories.loading
    }

    return (loading || isLoading() ? <div className="row mb-4" style={{width: '100%', height: '100%', verticalAlign: 'middle', textAlign:'center', position: 'fixed'}}>
        <div style={{margin: 'auto'}}>
            <Oval
                visible={isLoading()}
                height={width}
                width={height}
                strokeWidth={2}
                strokeWidthSecondary={2}
                color="#FF0000"
                secondaryColor="#000000"
                ariaLabel="oval-loading"
                wrapperStyle={{display: 'block', marginLeft: 22}}
            />
        </div>
    </div> : <></>)

}

export default Loader;
