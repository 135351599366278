export default class Modal {
    title;
    content;
    controls;

    constructor(title, content, controls) {
        this.title = title;
        this.content = content;
        this.controls = controls;
    }
}
