import "./style/bootstrap-custom.css";
import "./style/bootstrap-datatables.css";
import "./style/index.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import {library} from "@fortawesome/fontawesome-svg-core";
import {fas} from "@fortawesome/free-solid-svg-icons";
import {far} from "@fortawesome/free-regular-svg-icons";
import {fab} from "@fortawesome/free-brands-svg-icons";
import {HashRouter as Router} from "react-router-dom";
import store from "./store/Store";
import {Provider} from "react-redux";

library.add(fas, far, fab);

ReactDOM.render(
  <React.StrictMode>
    <Router>
        <Provider store={store}>
            <App/>
        </Provider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
