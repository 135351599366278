import ScrollToTopOnMount from "../../template/ScrollToTopOnMount";
import ContactForm from "./ContactForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AnimateSection from "../animate/AnimateSection";

const ContactView = () => {

    const key = process.env.REACT_APP_MAPS_KEY;
    const loc = process.env.REACT_APP_MAPS_LOCATION;
    const source = "https://www.google.com/maps/embed/v1/place?key=" + key + "&q=" + loc + "&zoom=15"
    const style = {fontWeight: 400};

    return <>
            <ScrollToTopOnMount/>
            <div id="bannerIndicators" className="carousel slide" data-bs-ride="carousel" style={{marginTop: "56px"}}>
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <div className="ratio bg-grey" style={{"--bs-aspect-ratio": "50%", maxHeight: "460px"}}>
                            <iframe width="100%" height="100%" src={source} style={{border: 0}}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2 g-3 mt-1 flex-shrink-0 row-cols-xl-3 w-100">&nbsp;</div>
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2 g-3 flex-shrink-0 row-cols-xl-3" style={{maxWidth: 1200, margin: '0 auto'}}>
                <AnimateSection>
                    <div className="col p-2 m-0">
                        <div className="card p-3">
                            <h5 className="text-center px-5" style={{display: 'inline-block', maxHeight: 23}}>
                                <img src={process.env.PUBLIC_URL + "/images/logo.png"} style={{width: 30, marginTop: -5}} alt=""/>
                                Tehnodidakta
                            </h5>
                            <h6 className="text-center px-5" style={style}>
                                Marshal Tito 28/6
                            </h6>
                            <h6 className="text-center px-5" style={style}>
                                2000, Shtip, North Macedonia
                            </h6>
                        </div>
                    </div>
                </AnimateSection>
                <AnimateSection>
                    <div className="col p-2 m-0">
                        <div className="card p-3">
                            <h6 className="text-center px-5" style={style}>
                                <a href="mailto:tennodidakta@t.mk" target="_blank">tennodidakta@t.mk</a>
                            </h6>
                            <h6 className="text-center px-5" style={style}>
                                <a href="tel: +3892383155" target="_blank">+389 (02) 383-155</a>
                            </h6>
                            <h6 className="text-center px-5" style={style}>
                                <a href="tel: +38970210850" target="_blank">+389 (70) 210-850</a>
                            </h6>
                        </div>
                    </div>
                </AnimateSection>
                <AnimateSection>
                    <div className="col p-2 m-0">
                        <div className="card p-3">
                            <h6 className="text-center px-5" style={style}>Work Hours:</h6>
                            <h6 className="text-center px-5" style={style}>Monday - Friday : 08:00 - 15:00</h6>
                            <h6 className="text-center px-5" style={style}>Saturday : 08:00 - 12:00</h6>
                        </div>
                    </div>
                </AnimateSection>
            </div>
            <div className="row row-cols-1 row-cols-md-1 row-cols-lg-1 g-3 mb-2 mt-2 flex-shrink-0 row-cols-xl-2 mb-4"
                 style={{maxWidth: 1200, margin: '0 auto'}}>
                <AnimateSection>
                    <div className="col p-1 m-0 mb-3">
                        <div className="card p-3 m-0">
                            <div className="d-flex flex-row align-items-center justify-content-center">
                                <p className="lead fw-normal mb-0 me-3">Find Us </p>
                                <div className="pull-right">
                                    <button type="button" className="btn btn-primary btn-floating mx-1">
                                        <FontAwesomeIcon icon={["fab", "facebook-f"]}/>
                                    </button>
                                    <button type="button" className="btn btn-primary btn-floating mx-1">
                                        <FontAwesomeIcon icon={["fab", "twitter"]}/>
                                    </button>
                                    <button type="button" className="btn btn-primary btn-floating mx-1">
                                        <FontAwesomeIcon icon={["fab", "linkedin"]}/>
                                    </button>
                                    <button type="button" className="btn btn-primary btn-floating mx-1">
                                        <FontAwesomeIcon icon={["fab", "instagram-square"]}/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </AnimateSection>
                <AnimateSection>
                    <div className="col p-1 m-0">
                        <ContactForm/>
                    </div>
                </AnimateSection>
            </div>
        </>;
}

export default ContactView;
