const Footer = () => {

    const year = new Date().getFullYear();

    return (
        <footer className="mt-auto py-5 bg-white">
            <div className="container d-flex justify-content-center">
              <span className="text-muted">Copyright {year} &nbsp;&copy;&nbsp;&nbsp;
                  <a href="https://arktype.net" target="_blank" rel="noreferrer" style={{textDecoration: 'none'}}>ArkType</a>
               </span>
            </div>
        </footer>
    );
}

export default Footer;
