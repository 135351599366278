import http from "../http/Axios";

class ContactService {

    async sendMessage(email, name, subject, message) {
        const payload = {email, name, subject, message};
        const res = http.post("/data/http/mail/send", payload);
        res.then(res => {
            return Promise.resolve(res);
        }).catch(error => {
            return Promise.reject(error);
        });
        return res;
    }

}
const service = new ContactService();
export default service;
