import useImageLoader from "../../hooks/ImageLoader";

const Image = ({image, text}) => {
    return (
        <div className={"carousel-item active"} data-bs-interval="5000">
            <div className="ratio" style={{"--bs-aspect-ratio": "50%", maxHeight: "460px"}}>
                <img className="d-block w-100 h-100 bg-grey cover" alt="" src={image}/>
            </div>
            <div className="carousel-caption d-none d-lg-block">
                <p>{text ?? ''}</p>
            </div>
        </div>
    );
}

const BannerImage = ({image, text}) => {

    useImageLoader([image]);

    return (
        <div id="bannerIndicators"  className="carousel slide"
             data-bs-ride="carousel"
             style={{marginTop: "56px"}}>
            <div className="carousel-inner">
                <Image image={image} active={true} text={text}/>
            </div>
        </div>
    );
}

export default BannerImage;
