import Icons from "../../models/Icons";
import Patterns from "../../models/Patterns";

export const firstNameField = {
    name: 'firstName',
    label: 'First Name',
    type: 'text',
    id: 'firstName',
    placeholder: 'First Name',
    icon: Icons.FIRST_NAME,
    showLabel: false,
    validation: {
        required: {
            value: true,
            message: '*',
        },
        maxLength: {
            value: 64,
            message: '64 max',
        },
        minLength: {
            value: 2,
            message: '2 min',
        },
        pattern: {
            value: Patterns.NAME_PART,
            message: Patterns.ERROR
        }
    },
    className: "form-control form-control-lg"
};

export const lastNameField = {
    name: 'lastName',
    label: 'Last Name',
    type: 'text',
    id: 'lastName',
    placeholder: 'Last Name',
    icon: Icons.LAST_NAME,
    showLabel: false,
    validation: {
        required: {
            value: true,
            message: '*',
        },
        maxLength: {
            value: 64,
            message: '64 max',
        },
        minLength: {
            value: 2,
            message: '2 min',
        },
        pattern: {
            value: Patterns.NAME_PART,
            message: Patterns.ERROR
        }
    },
    className: "form-control form-control-lg"
};

export const emailField = {
    name: 'email',
    label: 'E-Mail',
    type: 'text',
    id: 'email',
    placeholder: 'E-Mail Address',
    icon: Icons.EMAIL_ADDRESS,
    showLabel: false,
    validation: {
        maxLength: {
            value: 64,
            message: '64 max',
        },
        minLength: {
            value: 4,
            message: '4 min',
        },
        pattern: {
            value: Patterns.EMAIL,
            message: Patterns.ERROR
        }
    },
    className: "form-control form-control-lg"
};

export const addressField = {
    name: 'address',
    label: 'Street Address',
    type: 'text',
    id: 'address',
    placeholder: 'Street Address',
    icon: Icons.STREET_ADDRESS,
    showLabel: false,
    validation: {
        required: {
            value: true,
            message: '*',
        },
        maxLength: {
            value: 128,
            message: '128 max',
        },
        minLength: {
            value: 6,
            message: '6 min',
        }
    },
    className: "form-control form-control-lg"
};

export const address2Field = {
    name: 'address2',
    label: 'Street Address 2',
    type: 'text',
    id: 'address2',
    placeholder: 'Street Address 2',
    icon: Icons.STREET_ADDRESS,
    showLabel: false,
    validation: {
        maxLength: {
            value: 128,
            message: '128 max',
        },
        minLength: {
            value: 6,
            message: '6 min',
        }
    },
    className: "form-control form-control-lg"
};

export const cityField = {
    name: 'city',
    label: 'City',
    type: 'text',
    id: 'city',
    placeholder: 'City',
    icon: Icons.CITY,
    showLabel: false,
    validation: {
        required: {
            value: true,
            message: '*',
        },
        maxLength: {
            value: 64,
            message: '64 max',
        },
        minLength: {
            value: 2,
            message: '2 min',
        },
        pattern: {
            value: Patterns.COUNTRY,
            message: Patterns.ERROR
        }
    },
    className: "form-control form-control-lg"
};

export const zipField = {
    name: 'zip',
    label: 'Zip Code',
    type: 'number',
    id: 'zip',
    placeholder: 'Zip Code',
    icon: Icons.ZIP,
    showLabel: false,
    validation: {
        required: {
            value: true,
            message: '*',
        },
        maxLength: {
            value: 6,
            message: '6 max',
        },
        minLength: {
            value: 4,
            message: '4 min',
        },
        pattern: {
            value: Patterns.ZIP,
            message: Patterns.ERROR,
        }
    },
    className: "form-control form-control-lg"
};

export const stateField = {
    name: 'state',
    label: 'State',
    type: 'text',
    id: 'state',
    placeholder: 'State',
    icon: Icons.STATE,
    showLabel: false,
    validation: {
        required: {
            value: true,
            message: '*',
        },
        minLength: {
            value: 3,
            message: '3 min',
        },
        maxLength: {
            value: 32,
            message: '32 max',
        },
        pattern: {
            value: Patterns.COUNTRY,
            message: Patterns.ERROR
        }
    },
    className: "form-control form-control-lg"
};

export const countryField = {
    name: 'country',
    label: 'Country',
    type: 'text',
    id: 'country',
    placeholder: 'Country',
    icon: Icons.COUNTRY,
    showLabel: false,
    validation: {
        required: {
            value: true,
            message: '*',
        },
        minLength: {
            value: 3,
            message: '3 min',
        },
        maxLength: {
            value: 32,
            message: '32 max',
        },
        pattern: {
            value: Patterns.COUNTRY,
            message: Patterns.ERROR,
        }
    },
    className: "form-control form-control-lg"
};

export const billingAsShippingField = {
    name: 'useBillingAsShipping',
    label: 'Use billing address as shipping address',
    type: 'checkbox',
    id: 'useBillingAsShipping',
    placeholder: 'Use billing address as shipping address',
    validation: {
        pattern: {
            value: "[0-1]",
            message: 'bad format',
        }
    },
    className: "form-control form-control-lg"
};
