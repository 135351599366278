import OrderDetail from "./OrderDetail";
import {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {Routes} from "../../models/Route";

const OrderReviewView = ({state}) => {

    const history = useHistory();

    useEffect(() => {
        if (!state.order) history.push(Routes.CHECKOUT);
    }, [state.order])

    return (state.order ? <OrderDetail order={state.order} /> : <></>)
}
export default OrderReviewView;
