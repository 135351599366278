import {Link} from "react-router-dom";
import ProductCardV from "./ProductCardV";
import ProductCardH from "./ProductCardH";
import {useEffect, useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ScrollToTopOnMount from "../../template/ScrollToTopOnMount";
import {useDispatch, useSelector} from "react-redux";
import {findFilters, searchProducts} from "../../actions/Products";
import {Filter, Search, Sort} from "../../models/Search";
import Breadcrumbs from "../breadcrumbs/Breadcrumbs";
import StateTool from "../../tools/StateTool";
import ProductPagination from "./ProductPagination";
import {Routes} from "../../models/Route";
import useAnalytics from "../../hooks/Analytics";
import {Event} from "../../models/Analytics";

const FilterMenu = ({state, setState}) => {

    const [_state, _setState] = useState({...state});
    const filters = useSelector(state => state.products.filters);
    const tool = new StateTool(_state, _setState);
    const init = useRef(false);
    const key = "product.search";

    const addMaterial = (value, checked) => {
        const filters = _state.filters || [];
        const val = "%" + value + "%";
        if (checked) {
            let filter = findFilter(_state, "material", "ilike", val);
            if (filter) filter.value = val;
            else filters.push(new Filter("material", "ilike", val))
        } else {
            let filter = findFilter(_state, "material", "ilike", val);
            if (filter) filters.splice(filters.indexOf(filter), 1);
        }
        tool.set("filters", filters);
    }

    const addDimension = (value, checked) => {
        const filters = _state.filters || [];
        const val = "%" + value + "%";
        if (checked) {
            let filter = findFilter(_state, "dimension", "ilike", val);
            if (filter) filter.value = val;
            else filters.push(new Filter("dimension", "ilike", val))
        } else {
            let filter = findFilter(_state, "dimension", "ilike", val);
            if (filter) filters.splice(filters.indexOf(filter), 1);
        }
        tool.set("filters", filters);
    }

    const setPrice = (value, type) => {
        const filters = _state.filters || [];
        let filter;
        switch (type) {
            case "max":
                filter = findFilter(_state, "price", "lte");
                if (filter) filter.value = parseInt(value);
                else filters.push(new Filter("price", "lte", parseInt(value)))
                break;
            case "min":
                filter = findFilter(_state, "price", "gte");
                if (filter) filter.value = parseInt(value);
                else filters.push(new Filter("price", "gte", parseInt(value)))
                break;
        }
        tool.set("filters", filters);
    }

    const onApply = () => {
        setState({
            ...state,
            ..._state,
            page: 1,
            grid: state.grid,
            size: state.size
        })
    }

    useEffect(() => {
        if (state && init.current)
            localStorage.setItem(key, JSON.stringify(state));
        init.current = true;
    }, [state])

    return (
        <ul className="list-group list-group-flush rounded">
            <li className="list-group-item d-none d-lg-block">
                <h6 className="mt-1 mb-2">Category</h6>
                <div className="d-flex flex-wrap my-2">
                    {filters?.categories?.map((v, i) =>
                        <Link key={i} to={Routes.PRODUCTS} className={`btn btn-sm btn-outline-dark rounded-pill me-2 mb-2 
                        ${state.category?.id === v.id ? 'active' : ''}`}
                              onClick={_ => setCategory(_state, _setState, v)}>
                            {v.name.replace('Signs', '').replace("signs", "")}
                        </Link>
                    )}
                    <Link to={Routes.PRODUCTS} className="btn btn-sm btn-outline-dark rounded-pill me-2 mb-2"
                          onClick={e => setCategory(_state, _setState, undefined)}>
                        None
                    </Link>
                </div>
            </li>
            <li className="list-group-item">
                <h6 className="mt-1 mb-1">Dimension</h6>
                <div className="d-flex flex-column">
                    {filters?.dimensions?.map((v, i) => {
                        return (
                            <div key={i} className="form-check">
                                <input className="form-check-input" type="checkbox"
                                       onClick={e => addDimension(v, e.target.checked)}/>
                                <label className="form-check-label">
                                    {v}
                                </label>
                            </div>
                        );
                    })}
                </div>
            </li>
            <li className="list-group-item">
                <h6 className="mt-1 mb-1">Material</h6>
                <div className="d-flex flex-column">
                    {filters?.materials?.map((v, i) => {
                        return (
                            <div key={i} className="form-check">
                                <input className="form-check-input" type="checkbox"
                                       onClick={e => addMaterial(v, e.target.checked)}/>
                                <label className="form-check-label">
                                    {v}
                                </label>
                            </div>
                        );
                    })}
                </div>
            </li>
            <li className="list-group-item">
                <h6 className="mt-1 mb-2">Price</h6>
                <div className="row">
                    <div className="form-floating mb-2 col col-md-6 d-inline-block">
                        <input type="number" className="form-control" placeholder="Min" defaultValue="5" min="1"
                               onChange={e => setPrice(e.target.value, "min")}/>
                        <label>Min Price</label>
                    </div><br/>
                    <div className="form-floating mb-2 col col-md-6 d-inline-block">
                        <input type="number" className="form-control" placeholder="Max" defaultValue="5000" max="5000"
                               onChange={e => setPrice(e.target.value, "max")}/>
                        <label>Max Price</label>
                    </div>
                </div>
            </li>
            <li className="list-group-item row">
                <button className="btn btn-dark" onClick={onApply}>Apply</button>
            </li>
        </ul>
    );
}

const ProductListView = () => {

    const _state = localStorage.getItem("product.search") ? JSON.parse(localStorage.getItem("product.search")) : undefined;
    const [state, setState] = useState(_state ?? {
        grid: true,
        category: null,
        filters: [new Filter("price", "gte", 5), new Filter("price", "lte", 1000)],
        sorts: [new Sort("sku", 1)],
        page: 1,
        size: 12,
    });
    const [show, setShow] = useState(false);
    const [keyword, setKeyword] = useState();
    const analytics = useAnalytics();
    const dispatch = useDispatch();
    const filters = useSelector(state => state.products.filters);
    const products = useSelector(state => state.products.data);
    const loading = useSelector(state => state.products.loading);
    const tool = new StateTool(state, setState);
    const submitted = useRef(false);

    useEffect(() => {
        if (!filters)
            dispatch(findFilters());
    }, [filters])

    useEffect(() => {
        dispatch(searchProducts(createSearch()));
        analytics.event(Event.create(Event.SEARCH, {
            category: state.category?.name,
            query: keyword,
            material: findFilter(state, "material")?.value[0],
            dimension: findFilter(state, "dimension")?.value[0]
        }));
    }, [state])

    useEffect(() => {
        if (!products) return;
        setShow(true);
        if (!submitted.current) {
            analytics.reset();
            analytics.event(Event.create(Event.VIEW_ITEM_LIST, {
                item_list_id: "product_search",
                item_list_name: "Search Products",
                items: products?.data?.map((p, i) => analytics.product(p, i))
            }));
        }
        submitted.current = true;
        return () => {
            setShow(false);
        }
    }, [products])

    const createSearch = () => {
        return new Search(state.page, state.size, state.filters || [], state.sorts || []);
    }

    const onSearch = () => {
        const filters = state.filters || [];
        const filter = filters.find(f => f.property === 'name');
        if (!keyword || keyword.length === 0) {
            if (filter) filters.splice(filters.indexOf(filter), 1);
        } else {
            if (filter) filter.value = "%" + keyword + "%";
            else filters.push(new Filter("name", "ilike", "%" + keyword + "%"))
        }
        tool.apply({
            page: 1,
            filters: filters
        });
    }

    const onProductSelect = (product) => {
        analytics.reset();
        analytics.event(Event.create(Event.SELECT_ITEM, {
            item_list_id: "product_search",
            item_list_name: "Search Products",
            items: [analytics.product(product, products.data.indexOf(product))]
        }))
    }

    return (
        <div className="container mt-5 py-4 px-xl-5">
            <ScrollToTopOnMount/>
            {filters && <Breadcrumbs data={state?.category ? ["Product Catalog", state?.category?.name] : ["Product Catalog"]}
                                     links={[Routes.PRODUCTS]}/>}

            {filters && <div className="h-scroller d-block d-lg-none">
                <nav className="nav h-underline">
                    {filters?.categories?.map((c, i) => {
                        return (
                            <div key={i} className="h-link me-2">
                                <Link to={Routes.PRODUCTS} className={`btn btn-sm btn-outline-dark rounded-pill 
                                ${state.category?.id === c.id ? 'active' : ''}`}
                                      onClick={_ => setCategory(state, setState, c)}>
                                    {c.name.replace('Signs', '').replace("signs", "")}
                                </Link>
                            </div>
                        );
                    })}
                    <div key={"x"} className="h-link me-2">
                        <Link to={Routes.PRODUCTS} className="btn btn-sm btn-outline-dark rounded-pill me-2 mb-2"
                              onClick={e => setCategory(state, setState, undefined)}>
                            None
                        </Link>
                    </div>
                </nav>
            </div>}

            {filters && <div className="row mb-3 d-block d-lg-none">
                <div className="col-12">
                    <div id="accordionFilter" className="accordion shadow-sm">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button fw-bold collapsed" type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseFilter" aria-expanded="false"
                                        aria-controls="collapseFilter">
                                    Filter Products
                                </button>
                            </h2>
                        </div>
                        <div id="collapseFilter" className="accordion-collapse collapse"
                             data-bs-parent="#accordionFilter">
                            <div className="accordion-body p-0">
                                <FilterMenu state={state} setState={setState}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}

            {filters && <div className="row mb-4 mt-lg-3">
                <div className="d-none d-lg-block col-lg-3">
                    <div className="border rounded shadow-sm">
                        <FilterMenu state={state} setState={setState}/>
                    </div>
                </div>
                <div className="col-lg-9">
                    <div className="d-flex flex-column h-100">
                        <div className="row mb-3">
                            <div className="col-lg-3 d-none d-lg-block">
                                <select className="form-select" value={products?.size || 12}
                                        onChange={(e) => tool.apply({size: parseInt(e.target.value), page: 1})}>
                                    <option value="12">12 Records</option>
                                    <option value="18">18 Records</option>
                                    <option value="24">24 Records</option>
                                    <option value="30">30 Records</option>
                                </select>
                            </div>
                            <div className="col-lg-9 col-xl-5 offset-xl-4 d-flex flex-row">
                                <div className="input-group">
                                    <input className="form-control" type="text" placeholder="Search products..."
                                           aria-label="search input" onChange={e => setKeyword(e.target.value)}/>
                                    <button className="btn btn-outline-dark" onClick={e => onSearch()}>
                                        <FontAwesomeIcon icon={["fas", "search"]}/>
                                    </button>
                                </div>
                                <button className="btn btn-outline-dark ms-2 d-none d-lg-inline"
                                        onClick={e => tool.set("grid", !state.grid)}>
                                    <FontAwesomeIcon icon={["fas", state.grid ? "th-list" : "th-large"]}/>
                                </button>
                            </div>
                        </div>
                        <div className={"row row-cols-1 row-cols-md-2 row-cols-lg-2 g-3 mb-4 flex-shrink-0 " +
                        (state.grid ? "row-cols-xl-3" : "row-cols-xl-2")}>
                            {show && products?.data?.map((p, i) => {
                                return state.grid ?
                                    <ProductCardV key={p.sku} product={p} percentOff={i % 2 === 0 ? 15 : null}
                                                  onClick={onProductSelect}/>
                                    :
                                    <ProductCardH key={p.sku} product={p} percentOff={i % 4 === 0 ? 15 : null}
                                                  onClick={onProductSelect}/>
                            })}
                        </div>
                        {!loading && show && products?.data?.length === 0 && <h5>No results found</h5>}
                        {<div className="d-flex align-items-center mt-auto">
                            <span className="text-muted small d-none d-md-inline">
                              Showing {products?.page * products?.size}-{(products?.page + 1) * products?.size} of {products?.count}
                            </span>
                            <ProductPagination products={products} state={state} setState={setState}/>
                        </div>}
                    </div>
                </div>
            </div>}
        </div>
    );
}

export default ProductListView;

const findFilter = (state, property, operator, value) => {
    return state.filters?.find(f =>
        (f.property === property && f.operator === operator) || (f.property === property && f.operator === operator && f.value === value));
}

const setCategory = (state, setState, category) => {
    const filters = state.filters || []
    if (category) {
        let filter = findFilter(state, "category.id", "eq");
        if (filter) filter.value = category.id;
        else {
            filter = new Filter("category.id", "eq", category.id);
            filters.push(filter);
        }
    } else {
        const filter = findFilter(state, "category.id", "eq");
        if (filter) filters.splice(filters.indexOf(filter), 1);
    }
    setState({
        ...state,
        category: category,
        filters: filters,
        page: 0
    });
}
