import React, {forwardRef, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import Avatar from './Avatar'
import axios from "axios";
import Axios from "../../http/Axios";
import styles from './style.css'

const STATUS = {
    LOADED: 'loaded',
    SOURCE: 'src',
    FALLBACK: 'fallback',
    AVATAR: 'avatar'
}

const IMAGE_SHAPE = {
    ROUND: 'round'
}

const Image = forwardRef((props, img) => {

    // const img = useRef();
    const [state, setState] = useState({
        imageStatus: STATUS.SOURCE,
        src: undefined
    });
    const source = axios.CancelToken.source();

    const loadSource = () => {
        return Axios({
            method: 'GET',
            url: process.env.REACT_APP_PROCESS_API_URL + 'data/media/' + props.id,
            responseType: 'blob',
            cancelToken: source.token
        }).then(response => {
            if (response) {
                blobToBase64(response.data).then(result => {
                    setState({...state, src: result});
                });
                return Promise.resolve(response);
            } else
                return Promise.reject('An unknown error occurred');
        }).catch(err => {
            console.error("image.request.error", err);
        });
    }

    useEffect(() => {
        loadSource()
        return () => {
            try {
                source.cancel();
            } catch (error) {
                console.error(error);
            }
        }
    }, [])

    const getAvatar = () =>
        Avatar(
            props.avatarOptions?.shape,
            props.title,
            props.avatarOptions?.color,
            props.avatarOptions?.backgroundColor,
            props.width,
            props.height
        )

    const changeImageSrc = () => {
        switch (state.imageStatus) {
            case STATUS.SOURCE:
                setState({...state, imageStatus: STATUS.FALLBACK})
                img.current.src = props.fallbackSrc
                break
            case STATUS.FALLBACK:
                setState({...state, imageStatus: STATUS.AVATAR})
                img.current.src = getAvatar()
                break
            default:
                break;
        }
    }

    const blobToBase64 = async (blob) => {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    }

    return !!state.src ?
        <img src={state.src} onError={changeImageSrc}
             onClick={() => props.onClick(props.id)}
             className={`${props.className} ${
                 props.shape === IMAGE_SHAPE.ROUND ? styles[IMAGE_SHAPE.ROUND] : ''
             }${props.keepAspectRatio ? styles.keepAspectRatio : ''}`}
             ref={img}
             title={props.title}
             width={props.width}
             height={props.height}
             style={props.style}
             alt={''}
        /> : <></>
});

Image.propTypes = {
    id: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
}
Image.defaultProps = {
    onClick: () => {
    }
}

export default Image
