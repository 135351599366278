import http from "../http/Axios";
import {Grant} from "../models/Authentication";

class AuthService {

    authentication;
    USER_AUTH = "user";
    USER_DEFAULT = {
        username: process.env.REACT_APP_PROCESS_API_CLIENT_ID,
        password: process.env.REACT_APP_PROCESS_API_CLIENT_SECRET,
        application: process.env.REACT_APP_PROCESS_API_APPLICATION,
        tenant: process.env.REACT_APP_PROCESS_API_TENANT,
    };

    async init() {
        const item = localStorage.getItem(this.USER_AUTH);
        this.authentication = item ? JSON.parse(item) : null;
        return this.authentication;
    }

    async authenticate({username, password}) {
        const payload = {username, password, grant: Grant.Password}
        return this.token(payload)
    }

    async refresh() {
        const payload = {token: this.authentication?.refresh, grant: Grant.Refresh};
        return this.token(payload);
    }

    async token(payload) {
        const res = http.post("/auth/token", payload);
        res.then(res => {
            if (res.status === 200) {
                this.authentication = res.data;
                localStorage.setItem(this.USER_AUTH, JSON.stringify(this.authentication));
            }
            return Promise.resolve(res);
        }).catch(error => {
            this.logout();
            return Promise.reject(error);
        })
        return res;
    }

    async register({email, username, password, firstName, lastName}) {
        const payload = {email, username, password, firstName, lastName};
        const res = http.post("/auth/user/register", payload);
        res.then(res => {
            if (res.status === 200) {
                this.authentication = res.data;
                localStorage.setItem(this.USER_AUTH, JSON.stringify(this.authentication));
            }
            return Promise.resolve(res);
        }).catch(error => {
            this.logout();
            return Promise.reject(error);
        });
        return res;
    }

    async authorize({username, password, grant}) {
        console.info("auth.authorize.init");
        const payload = {username, password, grant};
        const res = http.post("/auth/code", payload);
        res.then(res => {
            return Promise.resolve(res);
        }).catch(error => {
            this.logout();
            return Promise.reject(error);
        });
        return res;
    }

    async password({username, code, password}) {
        const payload = {username, code, password};
        const res = http.put("/auth/user/password", payload);
        res.then(res => {
            return Promise.resolve(res);
        }).catch(error => {
            return Promise.reject(error);
        });
        return res;
    }

    async user() {
        return http.get("/auth/user");
    }

    async update(user) {
        return http.patch("/auth/user", user);
    }

    async logout() {
        this.authentication = undefined;
        localStorage.removeItem(this.USER_AUTH);
        return true;
    }

    get isGuest() {
        if (!this.authentication) return true;
        return this.authentication?.username === this.USER_DEFAULT.username;
    }

}
const service = new AuthService();
export default service;
