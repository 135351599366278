import {useFormContext} from "react-hook-form";
import FormTool from "./../../tools/FormTool"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

export const Input = ({
                          name,
                          label,
                          type,
                          id,
                          placeholder,
                          className,
                          validation,
                          icon,
                          showLabel,
                          onChange,
                          value,
                          disabled
                      }) => {

    const borderRegular = '1px solid rgb(227,227,227)';
    const borderError = "1px solid red";
    const {register, setValue, formState: {errors}} = useFormContext()
    const inputError = FormTool.findErrors(errors, name)
    const isInvalid = FormTool.isInvalid(inputError)
    if (validation?.required)
        validation.required.value = !!!disabled;

    return (
        <div className="flex flex-col w-full gap-2 position-relative">
            <div className="flex justify-between position-absolute" style={{color: 'red', right: 0, zIndex: 111}}>
                {isInvalid && <InputError message={inputError.error.message} key={inputError.error.message}/>}
            </div>
            {showLabel && <label htmlFor={id}>{label}</label>}
            <div className="input-group input-group-lg" style={{
                height: type === 'checkbox' ? 0 : 'auto',
                border: type === 'checkbox' ? 'none' : (isInvalid ? borderError : borderRegular)
            }}>
                {icon && <div className="input-group-prepend">
                    <span className="input-group-text">
                        <FontAwesomeIcon icon={icon}/>
                    </span>
                </div>}
                <input {...register(name, {...validation})}
                       name={name}
                       id={id}
                       type={type}
                       className={className}
                       placeholder={placeholder}
                       value={value}
                       disabled={disabled}
                       onChange={(e) => {
                           onChange(e);
                           setValue(name, type !== 'checkbox' ? e.target.value : e.target.checked)
                       }}/>
            </div>
        </div>
    )
}

Input.propTypes = {
    showLabel: PropTypes.bool,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    icon: PropTypes.any,
    value: PropTypes.any,
    validation: PropTypes.any,
    placeholder: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
}

Input.defaultProps = {
    onChange: (e) => {
        // console.info(e)
    }
}

export default Input;

const InputError = ({message}) => {
    return (
        <p className="flex items-center gap-1 px-2 font-semibold text-red-500 bg-red-100 rounded-md"
           {...frameError} >
            {message}
        </p>
    )
}

const frameError = {
    initial: {opacity: 0, y: 10},
    animate: {opacity: 1, y: 0},
    exit: {opacity: 0, y: 10},
    transition: {duration: 0.2},
}

export {InputError};
