import {FormProvider, useForm} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Input} from "../form/Input";
import Icons from "../../models/Icons";
import {Link, useHistory} from "react-router-dom";
import {authorize, password} from "../../actions/Auth";
import StateTool from "../../tools/StateTool";
import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import {Routes} from "../../models/Route";
import {Grant} from "../../models/Authentication";
import {codeField, emailField, passwordField} from "./UserPasswordFields";
import Message from "../form/Message";
import {Event} from "../../models/Analytics";
import useAnalytics from "../../hooks/Analytics";
import useImageLoader from "../../hooks/ImageLoader";
import ErrorTool from "../../tools/ErrorTool";

const UserPasswordView = () => {

    const image = process.env.PUBLIC_URL + "/images/splash_01.png";
    useImageLoader([image]);
    const form = useForm({mode: "onBlur", reValidateMode: "onBlur"});
    const [state, setState] = useState({
        submitted: false,
        error: undefined
    });
    const tool = new StateTool(state, setState);
    const dispatch = useDispatch();
    const history = useHistory();
    const analytics = useAnalytics();
    const loading = useSelector(state => state.auth.loading);

    const onSubmit = form.handleSubmit(data => {
        tool.set("error", undefined);
        if (!state.submitted)
            dispatch(authorize(data?.email, undefined, Grant.Identity))
                .then(_ => {
                    tool.apply({
                        submitted: true,
                        error: undefined
                    });
                    analytics.event(Event.create(Event.PASS_CODE, {}))
                })
                .catch(error => {
                    tool.apply({
                        submitted: false,
                        error: error.response?.data
                    });
                    sendErrorEvent(error);
                });
        else dispatch(password(data?.email, parseInt(data?.code), data?.password)).then(_ => {
            history.push(Routes.USER_SIGN_IN);
            analytics.event(Event.create(Event.PASS_RESET, {}))
        }).catch(error => {
            tool.set("error", error.response?.data);
            sendErrorEvent(error);
        })
    });

    const sendErrorEvent = (error) => {
        if (!error) return;
        analytics.event(Event.create(Event.EXCEPTION,
            {
                ...error.response?.data,
                description: error.response?.data?.message,
                fatal: false
            }
        ))
    }

    return <section className="" style={{marginTop: 100}}>
        <div className="container-fluid h-custom">
            <div className="row d-flex justify-content-center align-items-center h-100" style={{minHeight: 540}}>
                <div className="col-md-9 col-lg-6 col-xl-5 ta-center mb-4">
                    <img src={image} className="img-fluid" alt="User Operations"/>
                </div>
                <div className="col-md-8 col-lg-6 col-xl-3">
                    <FormProvider {...form}>
                        <form noValidate onSubmit={e => e.preventDefault()}>
                            <div
                                className="d-flex flex-row align-items-center justify-content-center justify-content-lg-start mb-4 h-50">
                                <p className="lead fw-normal mb-0 me-3">Sign in with</p>
                                <button type="button" className="btn btn-primary btn-floating mx-1">
                                    <FontAwesomeIcon icon={["fab", "facebook-f"]}/>
                                </button>

                                <button type="button" className="btn btn-primary btn-floating mx-1">
                                    <FontAwesomeIcon icon={["fab", "twitter"]}/>
                                </button>

                                <button type="button" className="btn btn-primary btn-floating mx-1">
                                    <FontAwesomeIcon icon={["fab", "linkedin"]}/>
                                </button>
                            </div>

                            <div className="form-outline mb-4">
                                <Input {...emailField} disabled={state.submitted}/>
                            </div>
                            {state.submitted && <>
                                <div className="form-outline mb-4">
                                    <Input {...codeField} />
                                </div>
                                <div className="form-outline mb-4">
                                    <Input {...passwordField} />
                                </div>
                            </>}
                            <div className="text-center text-lg-start mt-4 pt-2">
                                <div className="row">
                                    <div className="col col-md-6">
                                        <button type="button" className="btn btn-primary btn-lg float-start"
                                                onClick={onSubmit}
                                                style={{paddingLeft: '2.5rem', paddingRight: '2.5rem'}}
                                                disabled={loading}>
                                            <FontAwesomeIcon icon={Icons.USER_SIGN_UP}/> &nbsp; Submit
                                        </button>
                                    </div>
                                </div>
                                <div className="row">
                                    <p className="small fw-bold mt-2 pt-1 mb-0 ta-left">Don't have an account? &nbsp;
                                        <Link to={Routes.USER_SIGN_UP} className="link-danger">
                                            Register
                                        </Link>
                                    </p>
                                </div>
                                <div className="row col col-md-12 m-0 ta-left" style={{minHeight: 30}}>
                                    {state.error &&
                                    <Message level="warning" title="Password Reset Error" className="mt-4"
                                                            text={new ErrorTool(state.error).getMessage()} />}
                                </div>
                            </div>

                        </form>
                    </FormProvider>
                </div>
            </div>
        </div>

    </section>
}

export default UserPasswordView;
