import {useScriptLoader} from "../../hooks/ScriptLoader";

const ScriptLoader = () => {

    if (process.env.REACT_APP_ENV === 'production') {
        console.info = () => {};
        console.warn = () => {};
        console.error = () => {};
        console.debug = () => {};
        console.trace = () => {};
        console.log = () => {};
    }

    useScriptLoader('https://www.google.com/recaptcha/api.js');
    return <></>;
}

export default ScriptLoader;
