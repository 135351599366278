import Patterns from "../../models/Patterns";

export const cardHolderField = {
    name: 'cardHolder',
    label: 'Card Holder Name',
    type: 'text',
    id: 'cardHolder',
    placeholder: 'Card Holder',
    showLabel: false,
    validation: {
        required: {
            value: true,
            message: '*',
        },
        maxLength: {
            value: 40,
            message: '40 max',
        },
        minLength: {
            value: 5,
            message: '5 min',
        },
        pattern: {
            value: Patterns.FULL_NAME,
            message: "bad format"
        }
    },
    className: "form-control form-control-lg"
};

export const cardNumberField = {
    name: 'cardNumber',
    label: 'Card Number',
    type: 'number',
    id: 'cardNumber',
    placeholder: 'Card Number',
    showLabel: false,
    validation: {
        required: {
            value: true,
            message: '*',
        },
        maxLength: {
            value: 19,
            message: '19 max',
        },
        minLength: {
            value: 16,
            message: '16 min',
        },
        pattern: {
            value: Patterns.CREDIT_CARD_NUMBER,
            message: 'bad format'
        }
    },
    className: "form-control form-control-lg"
};

export const cardExpirationMonthField = {
    name: 'cardExpirationMonth',
    label: 'Expiry Month',
    type: 'number',
    id: 'cardExpirationMonth',
    placeholder: 'MM',
    showLabel: false,
    validation: {
        required: {
            value: true,
            message: '*',
        },
        maxLength: {
            value: 2,
            message: '2 max',
        },
        minLength: {
            value: 2,
            message: '2 min',
        },
        pattern: {
            value: Patterns.CREDIT_CARD_EXPIRATION_MONTH,
            message: 'invalid'
        }
    },
    className: "form-control form-control-lg"
};


export const cardExpirationYearField = {
    name: 'cardExpirationYear',
    label: 'Expiry Year',
    type: 'number',
    id: 'cardExpirationYear',
    placeholder: 'YYYY',
    showLabel: false,
    validation: {
        required: {
            value: true,
            message: '*',
        },
        maxLength: {
            value: 4,
            message: '4 max',
        },
        minLength: {
            value: 4,
            message: '4 min',
        },
        pattern: {
            value: Patterns.CREDIT_CARD_EXPIRATION_YEAR,
            message: 'invalid'
        }
    },
    className: "form-control form-control-lg"
};

export const cardCvvField = {
    name: 'cardCvv',
    label: 'Card CVV Number',
    type: 'number',
    id: 'cardCvv',
    placeholder: 'CVV',
    showLabel: false,
    validation: {
        required: {
            value: true,
            message: '*',
        },
        minLength: {
            value: 3,
            message: '3 min',
        },
        maxLength: {
            value: 4,
            message: '4 max',
        },
        pattern: {
            value: Patterns.CREDIT_CARD_CVV,
            message: 'bad format'
        }
    },
    className: "form-control form-control-lg"
};
