import {SEND_MESSAGE, SEND_MESSAGE_ERROR, SEND_MESSAGE_SUCCESS} from "./Types";
import ContactService from "../services/ContactService";

export const sendMessage = (email, name, subject, message) => async (dispatch) => {
    try {
        dispatch({
            type: SEND_MESSAGE,
        });
        const res = await ContactService.sendMessage(email, name, subject, message);
        if (res?.status === 200)
            dispatch({
                type: SEND_MESSAGE_SUCCESS,
                payload: res?.data,
            });
        else
            dispatch({
                type: SEND_MESSAGE_ERROR,
                payload: res?.data,
            });
        return Promise.resolve(res?.data);
    } catch (error) {
        dispatch({
            type: SEND_MESSAGE_ERROR,
            payload: error,
        });
        console.error(error);
        return Promise.reject(error);
    }
}
