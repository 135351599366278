import {useState} from "react";
import StateTool from "../../tools/StateTool";
import {Link} from "react-router-dom";
import {Routes} from "../../models/Route";

const UserTransactionsView = ({transactions}) => {

    const [state, setState] = useState({
        page: 1
    });
    const tool = new StateTool(state, setState);
    const size = 4;
    const count = transactions?.count;
    const pages = parseInt(count / size + (count%size > 0 ? 1 : 0) + '');
    console.info("count / pages " + count + " / " + pages)

    const getTransactions = (page, size) => {
        return transactions?.data?.slice((page - 1) * size, page * size)
    }

    return (transactions?.data?.length > 0 && <>
        <table className="table table-bordered table-striped dataTable">
            <tbody>
            {getTransactions(state.page, size)?.map((t, i) =>
                <tr key={i} className={{"odd": (i % 2 === 0)}}>
                    <td>
                        {t.orderId && <Link to={Routes.ORDER.replace(":id", t.orderId)}>{t.orderId}</Link>}
                        {!t.orderId && <span>Not Available</span>}
                    </td>
                    <td>{t.amount.toFixed(2)}</td>
                    <td>{t.timeCreated}</td>
                </tr>
            )}
            </tbody>
        </table>
        {pages > 1 &&
        <div style={{width: '100%', margin: '0 auto', overflow: 'hidden', display: 'block'}}>
            <ul className="pagination mt-1" style={{justifyContent: 'center'}}>
                {[...Array(pages)].map((x, i) =>
                    <li className={"page-item " + (state.page - 1 === i ? 'active' : '')} key={i}>
                        <a onClick={_ => tool.set("page", i + 1)} className="page-link">{i + 1}</a>
                    </li>
                )}
            </ul>
        </div>}
    </>)
}

export default UserTransactionsView;
